<template>
	<div class="container mt-4">
		<h2 class="label-header">Activation Pins</h2>
		<div class="card mb-4">
			<div class="card-body">
				<div class="d-flex justify-content-center flex-column align-items-center" v-if="isLoading1" style="width: 100%; heigth: 100px;">
					<span style="width: 30px; height: 30px;" class="spinner-border spinner-border-sm mr-3" role="status" aria-hidden="true"></span>Generating Pin
				</div>
				<form class="row g-3"  @submit.prevent="onGenerate" novalidate v-if="!isLoading1">
					<div class="col-12">
						<label class="form-label">Number of activation pins to generate</label>
						<select class="form-select" v-model="vv.key_count.$model">
							<option :value="initalVal">Selet count</option>
							<option v-for="(item, i) in numberList" :key="i" :value="item">{{ item }}</option>
						</select>
						<span class="error">{{ vv?.key_count?.$errors[0]?.$message }}</span>
					</div>
					<div class="col-12">
						<button class="btn btn-primary">Generate Pins</button>
					</div>
				</form>
			</div>
		</div>
		<div class="card mb-4">
			<div class="card-body">
				<div class="table-responsive">
					<table class="table table-hover table-striped table-bordered">
						<thead>
							<tr>
								<th>Pins</th>
								<th>Is Taken</th>
								<th width="150px">Action</th>
							</tr>
						</thead>
						<tbody v-if="isLoading">
							<tr>
								<td colspan="3">
									<div class="d-flex justify-content-center flex-column align-items-center">
										<span style="width: 30px; height: 30px;" class="spinner-border spinner-border-sm mr-3" role="status" aria-hidden="true"></span>Loading Data
									</div>
								</td>
							</tr>
						</tbody>
						<tbody v-if="!isLoading && pinList.length > 0">
							<tr v-for="(item, i) in pinList" :key="i">
								<td>
									{{ item.activation_code }}
								</td>
								<td>
									<span v-if="parseInt(item.is_taken) === 0" class="font-weight-bold text-info">Not Taken</span>
									<span v-if="parseInt(item.is_taken) === 1" class="font-weight-bold text-danger">Taken</span>
								</td>
								<td>
									<div class="btn-group">
										<button v-if="parseInt(item.is_taken) === 0" class="btn btn-sm btn-primary" style="margin-right: 10px;" @click="markTaken(item)">Mark As Taken</button>
									</div>
								</td>
							</tr>
						</tbody>
						<tbody v-if="!isLoading && pinList.length === 0">
							<tr>
								<td colspan="3">
									<div class="d-flex justify-content-center flex-column align-items-center">
										No Pin have been generated yet
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="d-flex justify-content-center flex-column align-items-center" v-if="lastIndex !== 0">
					<button class="btn btn-sm btn-secondary" @click="loadPage()">Load More</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { reactive, computed } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { activationService } from '@/services/activation.service'
export default {
	name: 'ActivationKey',
	setup () {
		const fform = reactive({
			key_count: null
		})
		const rules = computed(() => (
		{
			key_count: { required }
		}
		))
		const vv = useVuelidate(rules, fform);
		return {
			vv,
			fform
		}
	},
	data() {
		return {
			initalVal: null,
			isLoading1: false,
			isLoading: true,
			numberList: [],
			lastIndex: 0,
			pinList: []
		};
	},
	mounted() {
		this.loadCount()
		this.loadPage()
	},
	methods: {
		loadCount () {
			for(let i = 1; i < 100; i++) {
				this.numberList.push(i)
			}
		},
		loadPage () {
			activationService.loadPins(this.lastIndex)
				.then((response) => {
					this.isLoading = false
					if(parseInt(response.data.message_code) === 200){
						for(const item of response.data.pinList){
							this.pinList.push(item)
						}
						this.lastIndex = response.data.lastIndex
					}
				})
				.catch((e) => {
					this.isLoading = false
					console.log(e)
				})
		},
		onGenerate () {
			this.vv.$touch();
			if (this.vv.$invalid) return;
			this.isLoading1 = true
			activationService.generatePins(this.fform.key_count)
				.then((response) => {
					this.isLoading1 = false
					if(parseInt(response.data.message_code) === 200){
						this.lastIndex = 0
						this.pinList = []
						this.loadPage()
					}
				})
				.catch((e) => {
					this.isLoading1 = false
					console.log(e)
				})
		},
		markTaken (item) {
			this.isLoading = true
			activationService.markAsTaken(item.activation_id)
				.then((response) => {
					this.isLoading = false
					if(parseInt(response.data.message_code) === 200){
						this.lastIndex = 0
						this.pinList = []
						this.loadPage()
					}
				})
				.catch((e) => {
					this.isLoading = false
					console.log(e)
				})
			console.log(item)
		}
	}
};
</script>

<style scoped>
.container{
	background: var(--paul-color-light);
}
label{
	font-weight: 550;
	font-size: 14px;
	margin-bottom: 5px;
}
</style>
